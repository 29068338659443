.HH-main-body{
  /* background-color: #ebeef7; */
  background-color: #ffffff;
}

.HH-main-container {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: white;
    background-image: url("./img/start_bg_img.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow-y: scroll;
}

.HH-bg-image{
  position: absolute;
}

.HH-Language-image {
  width: 60px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.HH-main-title {
  font-size: 35px;
  margin-bottom: 20px;
  position: absolute;
  top: 10%;
}

.HH-line {
  margin-bottom: 10px;
}

.HH-bottom-items{
  margin-top: 90%;
}

.HH-email-input {
  width: 90%;
  height: 60px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  padding: 10px;
  display: inline;
  margin-top: 30%;
}

.HH-main-startButton {
    padding: 15px 30px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #01b6cd;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    width: 90%;
}

.HH-error-message {
    color: red;
    font-size: 12px;
    position: absolute;
    margin-top: 4px; /* 에러 메시지의 위치 조정 */
}

.HH-main-startButton:hover {
  background-color: #0092a5;
}

.HH-language-setting{
  display: block;
}

.HH-language-popup {
    position: absolute;
    top: 80px; 
    right: 20px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    z-index: 1000;
    width: 120px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

p.HH-language-popup-title{
  font-size: 20px;
  margin: 5px 0 20px 5px;
}

.HH-language-btn-group {
  display: grid;
  align-items: center;
}

.HH-language-btn{
  width: 90%;
  height: 30px;
  text-align: left;
  margin: 0 0 10px 0;
  background-color: #fff;
  border: none;
  padding-left: 10px;
  position: relative;
  cursor: pointer;
}

.HH-language-btn.selected {
  background-color: #e0f7fa; 
  width: 100%;
  font-weight: bold;
}

.HH-checkmark {
  position: absolute;
  right: 10px;
  color: #007acc; 
  font-weight: bold;
}

.HH-language-close-btn{
  width: 90%;
  height: 30px;
  text-align: center;
  background-color: #fff;
  border: none;
  color: #9a9a9a;
  margin: 10px 0 0px 10px;
}

@media (max-width: 600px) {
  .HH-main-startButton {
    font-size: 1rem;
    padding: 12px 25px;
  }
}
