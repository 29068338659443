.PrechannelB-list-item {
  height: 75px;
  background-color: #fff;
  flex-direction: column;
  padding: 0px 50px;
  border-bottom: 1px solid #c9c9c9;
  align-content: center;
  position: relative;
}

.PrechannelB-list-item:not(.selected):hover {
  background-color: #E3F2FD;
}

.PrechannelB-list-item.selected {
  background-color: #b2ebf2;
}

.PrechannelB-list-icon {
  align-items: center;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  margin-left: 10px;
  position: relative;
  width: 46px;
  border-radius: 50%;
}

.PrechannelB-list-icon-image {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 80%;
}

.PrechannelB-list-details {
  display: flex;
  gap: 7px;
  color: #000;
  align-self: center;
}

.PrechannelB-list-info {
  display: flex;
  flex-direction: column;
}

.PrechannelB-list-name-box {
  display: flex;
  flex-direction: row;
}

.PCL-stateNameBox{
  display: flex;
  width: 47%;
}

.PrechannelB-list-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.PrechannelB-list-name {
  font: 600 20px Baloo Paaji 2, -apple-system, Roboto, Helvetica, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  font-size: 18px;
}

.PrechannelB-list-name.min-content {
  width: min-content;
}

.PrechannelB-list-name.fixed-width {
  width: 117px;
}

.PrechannelB-list-user-info {
  font-size: 16px;
  white-space: nowrap;
  margin-left: 7px;
}

.PrechannelB-list-status {
  border-radius: 50%;
  width: 11px;
  height: 11px;
  margin: auto 0 auto 7px;
}

.PrechannelB-list-status-active {
  background-color: #4caf50;
}

.PrechannelB-list-status-inactive {
  background-color: #ccc;
}

.PrechannelB-list-department {
  margin-top: 5px;
  font-weight: bold;
  font: 400 14px Actor, sans-serif;
}

.PrechannelB-list-time {
  color: #2E7C9A;
  position: absolute;
  right: 44%;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 500;
}

.PrechannelB-list-unread-badge {
  align-items: center;
  background-color: #ff6c6c;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 12px;
  font-weight: 700;
  height: 21px;
  justify-content: center;
  position: absolute;
  right: 10.5%;
  width: 21px;
  top: 65%;
  transform: translateY(-50%);
}

/* 기존 PreChannelList.css 파일에 추가할 스타일 */

/* 상태 표시 아이콘 */
.status-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
}

.status-indicator.green {
  background-color: #4CAF50; /* 실제 진료로 진행된 경우 */
  align-self: center;
}

.status-indicator.red {
  background-color: #F44336; /* 사전 진료만 진행한 경우 */
  align-self: center;
}

/* Inactive 탭 스타일 */
.PrechannelB-list-time-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 20%;
}

.PrechannelB-list-start-time {
  font-size: 13px;
  color: #2F3637;
}

.PrechannelB-list-end-time {
  font-size: 12px;
  color: #888;
  margin-top: 2px;
}

.PrechannelB-list-department {
  font-size: 15px;
  color: #2E7C9A;
  margin-top: 2px;
  width: 30%;
  font-weight: 500px;
}

/* Active 탭과 Inactive 탭의 공통 스타일 개선 */
.PrechannelB-list-item {
  padding: 12px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s;
}

.PrechannelB-list-item:hover {
  background-color: #f5f5f5;
}

.PrechannelB-list-item.selected {
  background-color: #e8f4f8;
}



.PrechannelB-list-details {
  flex: 1;
}

.PrechannelB-list-name {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 2px;
  color:#2F3637;
  font-size: 24px;
}

.PrechannelB-list-user-info {
  font-size: 16px;
  color: #2F3637;
  align-self: center;
}

/* 기존 PreChannelList.css 파일에 추가할 스타일 */


/* Inactive 탭 스타일 */
.PrechannelB-list-time-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 200px;
  text-align: right;
}

.PrechannelB-list-time-header {
  font-size: 13px;
  color: #A8A7A7;
  margin-bottom: 4px;
}

.PrechannelB-list-time-range {
  font-size: 13px;
  color: #2F3637;
  font-weight: bold;
}

.PrechannelB-list-department {
  font-size: 14px;
  color: #2E7C9A;
  font-weight: bold;
}

/* Active 탭과 Inactive 탭의 공통 스타일 개선 */
.PrechannelB-list-item {
  padding: 12px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s;
}

.PrechannelB-list-item:hover {
  background-color: #f5f5f5;
}

.PrechannelB-list-item.selected {
  background-color: #e8f4f8;
}


.PrechannelB-list-details {
  flex: 1;
}

.PrechannelB-list-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.PCB-containerA{
  color: #2F3637;
  font-size: 24px;
  font-weight: bold;
}

.PCB-containerB{
  color: #2F3637;
  font-size: 15px;
  font-weight: bold;
}

.PCB-labelA{
  font-size: 16px;
  font-weight: 300;
}

.PCB-labelB{
  color: #2E7C9A;
  font-size: 15px;
}