.PE_combined-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px 20px 75px;
    -ms-overflow-style: none;
    height: calc(100vh - 150px);
    gap: 10px;
}

.PE-Title {
    font-size: 24px;
    color: #2E7C9A;
    font-weight: bold;
}


.PE-PatientPersonalInfoContainer {
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
    gap: 5px;
}

.PE-NameAgeGender {
    display: flex;
    align-items: center;
    gap: 5px;
}

.PE-Name {
    font-size: 20px;
    color: #2F3637;
    font-weight: bold;
}

.PE-AgeGender {
    font-size: 16px;
    color: #9CABAD;
}

.PE-Number {
    font-size: 14px;
    color: #000000;
}

.PE-Sub {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.PE-SubContainer {
    display: flex;
    flex-direction: column;
}

.PE-CheckBoxItemContainer {
    display: flex;
    gap: 5px;
    align-items: center;
    color: #5E5E5E;
    font-size: 14px;
}

.PE-SubTitleContainer {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
}

.PE-checkBoxItemOther {
    border: none;
    border-bottom: solid 1px #5E5E5E;
    width: 69%;
}

.PE-checkBoxItemOther:focus {
    outline: none;
}

.PE-SubTitleHolder {
    display: flex;
}

.PE-SubTItle {
    font-size: 16px;
    color: #2E7C9A;
    font-weight: bold;
    width: 93%;
}

.PE-MacroBtn {
    display: flex;
    width: 21px;
    height: 21px;
    background: none;
    border: none;
    justify-content: center;
    cursor: pointer;
    position: relative;
}

.PE-SubTitleBtnImage {
    width: 21px;
    height: 21px;
}

.PE-SubContent {
    font-size: 14px;
    color: #A8A7A7;
}

.PE-infoContainer {
    height: 86%;
    overflow-y: auto;
}

.PE-infoContainer::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
    border: none;
    background-color: #D3D8D9;
}

.PE-infoContainer::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 50px;
    border: none;
    background-color: #A4A4A4;
}

.PE-ContentBox {
    padding: 10px;
    width: 92%;
    border-radius: 5px;
    border: solid 1px #B9C3C4;
    resize: none;
}

.PE-ContentBox:focus {
    outline: none;
}

.PE-ContentBox::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
    border: none;
    background-color: #D3D8D9;
}

.PE-ContentBox::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 50px;
    border: none;
    background-color: #2E7C9A;
}

.PE-h65 {
    height: 45px;
}

.PE-h70 {
    height: 50px;
}

.PE-h85 {
    height: 65px;
}

.PE-h95 {
    height: 75px;
}

.PE-BtnContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.PE-Btn {
    width: 96%;
    height: 51px;
    border: solid 2px #01B6CD;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    place-content: center;
    cursor: pointer;
}

.PE-BtnSave {
    background-color: #01B6CD;
    color: #FFFFFF;
    font-size: 24px;
}

.PE-BtnComplete {
    background-color: #FFFFFF;
    color: #01B6CD;
    font-size: 22px;
}

.PE-MacroContainerRelative{
    position: relative;
}

.PE-MacroContainer{
    position: absolute;
    background-color: #fff;
    border: solid 1px #A8A7A7;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    right: 5px;
    top: -5px;
    text-align-last: left;
}

.PE-MacroString{
    font-size: 12px;
    color: #767676;
}

.PE-MacroType1{
    font-size: 12px;
    color: #01B6CD;
}

.PE-MacroType2{
    font-size: 12px;
    color: #2E7C9A;
}

.PE-MacroPQ{
    background-color: #fff;
    border: none;
    cursor: pointer;
    padding: 0px;
}

.PE-MacroMR{
    background-color: #fff;
    border: none;
    cursor: pointer;
    padding: 0px;
}