.filter-container {
    position: absolute;
    top: 180px;
    right: 37%;
    width: 450px;
    height: 632px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
    padding: 20px;
  }
  
  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 10px 20px;
  }
  
  .filter-title {
    font-size: 32px;
    color: #858585;
    font-weight: normal;
    margin: 0;
  }
  
  .clear-button {
    border: none;
    background: none;
    color: #5C7CFA;
    font-size:20px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .filter-section {
    border-top: 1px solid #A8A7A7;
  }
  
  .filter-section-header {
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .section-title {
    font-size: 20px;
    color: #2F3637;
  }

  input[type='radio'] {
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid #ccc; 
    border-radius: 50%;
    outline: none; 
    cursor: pointer;
  }
  
  
  input[type='radio']:checked {
    background-color: #5C7CFA;
    border: 3px solid white; 
    box-shadow: 0 0 0 1.6px #5C7CFA; 
  }
  
  .arrow-icon {
    width: 24px;
    height: 24px;
    transition: transform 0.3s;
  }
  
  .arrow-icon.expanded {
    transform: rotate(180deg);
  }
  
  .filter-options {
    padding: 0 20px;
    height: 0;
    overflow: hidden;
    transition: height 0.3s, padding 0.3s;
  }
  
  .filter-options.expanded {
    height: auto;
    padding: 15px 20px 15px 20px;
    border-top: solid 1px #A8A7A7;
  }
  
  .radio-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .radio-input, .checkbox-input {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  
  .option-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size:16px;
    color: #2F3637;
  }
  
  .status-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 10px;
  }
  
  .status-indicator.green {
    background-color: #03CA16;
  }
  
  .status-indicator.red {
    background-color: #FA5252;
  }
  
  .button-container {
    position: absolute;
    width: 84%;
    top: 88%;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .FC_cancel-button {
    width: 98px;
    height: 34px;
    background-color: #C9C9C9;
    border: none;
    border-radius: 30px;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 15px;
  }
  
  .FC_ok-button {
    width: 98px;
    height: 34px;
    background-color: #01B6CD;
    border: none;
    border-radius: 30px;
    color: white;
    cursor: pointer;
    font-size:15px;
  }
  
  /* 호버 효과 추가 */
  .cancel-button:hover {
    background-color: #c4c9ca;
  }
  
  .ok-button:hover {
    background-color: #019fb3;
  }
  
  .clear-button:hover {
    text-decoration: underline;
  }