.precon-container {
    margin: 0 auto;
    padding: 20px;
    position: absolute;
    background-color: #fff;
    border-radius: 10px;
    width: 657px;
    height: 569px;
    box-shadow: 0px 0px 10px #888888;
    top: 160px;
  }
  
  .precon-title {
    font-family: 'Figtree';
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 5px;
    padding-top: 10px;
    text-align: center;
    color: black;
  }
  
  .precon-subtitle {
    text-align: center;
    color: #666;
    margin-bottom: 20px;
    font-size: 20px;
  }
  
  .precon-search-container {
    margin: 0 auto 25px;
    width: 80%;
    max-width: 400px;
    position: relative;
  }
  
  .precon-search-input {
    width: 88%;
    padding: 12px 45px 12px 20px;
    border-radius: 25px;
    border: none;
    background-color: #e9eff2;
    font-size: 16px;
    color: #555;
    outline: none;
  }
  
  .precon-search-input::placeholder {
    color: #aaa;
  }
  
  .precon-search-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
    opacity: 0.6;
  }
  
  .precon-department-container {
    height: 325px;
    overflow-y: auto;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 32px;
  }
  
  .precon-department-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 12px;
    margin-left: 3px;
    margin-top: 3px;
  }
  
  .precon-department-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 2px solid #e8e8e8;
    border-radius: 12px;
    padding: 15px 10px;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    width: 110px;
    height: 95px;
  }
  
  .precon-department-button:hover {
    transform: translateY(-2px);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .precon-department-button.active {
    border: solid 2px #01b6cd;
    color: #01b6cd;
    background-color: rgba(1, 182, 205, 0.05);
  }
  
  .precon-department-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
  }
  
  .precon-department-name {
    font-size: 12px;
    text-align: center;
  }
  
  .precon-button-center {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
    text-align-last: center;
  }
  
  .precon_cancel-button {
    background-color: #b5c1c9;
    color: white;
    border: none;
    min-inline-size: max-content;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    min-width: 120px;
    width: 162px;
    height: 41px;
  }
  
  .precon_next-button {
    background-color: #01b6cd;
    color: white;
    border: none;
    inline-size: fit-content;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    min-width: 120px;
    height: 41px;
  }
  
  /* Scrollbar styles */
  .precon-department-container::-webkit-scrollbar {
    width: 6px;
  }
  
  .precon-department-container::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .precon-department-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  
  .precon-department-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .precon-department-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .precon-department-grid {
      grid-template-columns: repeat(3, 1fr);
    }
    
    .precon-title {
      font-size: 20px;
    }
    
    .precon-subtitle {
      font-size: 14px;
    }
  }