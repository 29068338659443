.QP-main-body{
  background-color: #ffffff;
  
}

.OP-question-group{
  /* position: absolute;
  top:200px; */
}

.QP-main-container {
    max-width: 480px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    overflow-y: scroll;
    padding: 20px;
    /* background-image: url("./img/question_bg_img.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
}

.QP-Language-image {
    width: 60px;
    display: flex;
    align-self: self-end;
    margin-bottom: 120px;
    top: 10px;
    position: absolute;
}

.QP-prgrsBar {
  text-decoration-color: #ccc;
  margin-bottom: 90px;
  color: gray;
}

.QP-main-title {
  font-size: 25px;
  margin-bottom: 90px;
}

.QP-bottom-items{
  position: relative;
  margin-top: 50px;
}

.QP-main-startButton {
    padding: 15px 30px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.QP-btn-answer {
  padding: 25px 15px;
  font-size: 30px;
  padding: 25px 50px;
  color: #fff;
  background-color: #2196F3;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin:20px;
  width: 150px;
  height: auto;
}

.QP-btn-answer:hover {
  background-color: #1e88e5;
}

.QP-btn-answer-no {
  padding: 25px 15px;
  font-size: 30px;
  padding: 25px 50px;
  color: #fff;
  background-color: #f32121;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin:20px;
  width: 150px;
  height: auto;
}

.QP-btn-answer-no:hover {
  background-color: #e51e1e;
}

@media (max-width: 600px) {
  .QP-main-title {
    font-size: 1.5rem;
  }
  .QP-btn-answer {
    font-size: 25px;
    padding: 20px 15px;
  }
}
