.wcc-app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
}

.wcc-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1;
}

.wcc-content {
  display: flex;
  flex: 1;
  height: 100%;
}

.wcc-gene-img {
  height: 37px;
  width: 37px;
}

.wcc-notification-button {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
}

.wcc-sidebar {
  width: 390px;
  background-color: #ffffff;
  overflow: hidden;
}

.wcc-main-content {
  display: flex;
  flex: 1;
  background-color: #ffffff;
}

.wcc-day-info .arrow-icon{
  transform: rotate(90deg);
}

.wcc-user-calls-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  -ms-overflow-style: none;
  padding-bottom: 15px;
  height: calc(100vh - 180px);
  min-height: 500px; /* 최소 높이 설정 */
  max-height: 800px; /* 최대 높이 설정 */
}

.wcc-user-calls-list::-webkit-scrollbar {
  width: 10px;
  border-radius: 50px;
  border: none;
  background-color: #D3D8D9;
}

.wcc-user-calls-list::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 50px;
  border: none;
  background-color: #2E7C9A;
}

.wcc-user-call {
  background-color: #ffffff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wcc-chat-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
  height: 100%;
  width: 100%;
}

.wcc-patient-info-container {
  border: 1px solid #ddd;
  overflow-y: auto;
  height: 100%;
  flex-shrink: 0;
  width: 25%;
  min-width: 385px;
  background: #FFFFFF;
}

.wcc-patient-info-container::-webkit-scrollbar {
  display: none;
}

.wcc-search {
  display: flex;
  align-items: center;
  height: auto;
}

.wcc-gene-div {
  background-color: #fff;
  display: flex;
  gap: 22px;
  padding: 0 12px;
  width: 100%;
}

.wcc-drprofile-img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.wcc-drprofile-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.wcc-tabs {
  display: flex;
  position: relative;
  font-family: 'Actor', sans-serif;
}

.tab {
  flex: 1;
  padding: 10px;
  text-align: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #A8A7A7;
  transition: color 0.3s;
  font-family: 'Actor', sans-serif;
  border-radius: 0px;
}

.tab.active[data-tab='active'] {
  color: #03CA16;
  font-family: 'Actor', sans-serif;
  font-size: 20px;
  font-weight: bold;
  border-bottom: solid 3px #03CA16;
  cursor: default;
}

.other-tab-count {
  padding: 0px 5px;
  font-size: 20px;
  color: #fa5252;
  font-weight: bold;
}

.tab.active[data-tab='inactive'] {
  color: #FA5252;
  font-family: 'Actor', sans-serif;
  font-size: 20px;
  font-weight: bold;
}

.tab-indicator {
  position: absolute;
  bottom: 0;
  height: 3px;
  transition: 0.3s ease-in-out;
}

.tab-indicator.active {
  background-color: #03CA16;
}

.tab-indicator.inactive {
  background-color: #FA5252;
}

.wcc-logo-image {
  width: auto;
  height: 40px;
  margin-right: 40px;
}

.wcc-search-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 14px 0;
}

.wcc-search-icon {
  position: absolute;
  left: 92%;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  pointer-events: none;
  opacity: 0.5;
}

.wcc-search-input {
  width: 95%;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  background-color: #f5f5f5;
  color: #333;
  padding-top: 4%;
  padding-bottom: 4%;
  padding-left: 5%;
}

.wcc-search-input::placeholder {
  padding-left: 4%;
}

.wcc-search-input:focus {
  border: none;
}

.wcc-header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.wcc-logout {
  margin-right: 15px;
  padding: 8px 15px;
  background-color: #ff5f5f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.wcc-rate-container {
  align-items: center;
  display: flex;
  margin-right: 15px;
  font-weight: bold;
  font-size: 16px;
}

.wcc-rate-img {
  width: 27px;
  height: 27px;
  margin-right: 5px;
}

.wcc-income-button {
  margin-left: 10px;
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.wcc-income-button:hover {
  background-color: #45a049;
}

.wcc-main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F1F9FC;
}

.income-page {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.wcc-call-list {
  background-color: #fff;
  padding: 15px;
  margin: 10px;
  border-radius: 15px;
  box-shadow: 0px 4px 20px 0px #2E7C9A33;
}

.wcc-call-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.wcc-call-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.wcc-call-list-title {
  font-size: 24px;
  font-weight: bold;
  color: #2E7C9A;
}

.wcc-call-count {
  font-size: 20px;
  font-weight: 400;
  color: #2E7C9A;
}

/* Settlement Details Styles */
.wcc-settlement-details {
  padding: 20px;
  width: 100%;
  overflow-y: auto;
}

.wcc-settlement-header-container {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 30px 20px 0px 20px;
}

.wcc-settlement-header-img {
  width: 40px;
  height: 40px;
}

.wcc-settlement-title {
  font-size: 20px;
  color: #2E7C9A;
  font-weight: bold;
}

.wcc-settlement-table-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: 20px 0;
  background: white;
  border-radius: 8px;
  max-height: 600px;
  overflow-y: auto;
}

.wcc-income-table {
  min-width: 750px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.wcc-income-table th {
  background-color: #2E7C9A;
  padding: 16px;
  text-align: left;
  font-weight: 600;
  color: #fff;
  font-size: 16px;
  white-space: nowrap;
  position: sticky;
  top: 0;
}

.wcc-income-table td {
  padding: 12px;
  border-bottom: 1px solid #A8A7A7;
  font-size: 14px;
}

.wcc-income-table td:first-child {
  padding-left: 100px;
}

.wcc-income-status-completed {
  color: #01B6CD;
  font-size: 16px;
  font-weight: bold;
}

.wcc-income-status-pending {
  color: #839699;
  font-size: 16px;
}

.wcc-arrow-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.wcc-arrow-icon {
  height: 24px;
  width: 24px;
  transition: transform 0.2s ease;
}

/* Weekly View Styles */
.wcc-weekly-view {
  background: white;
  overflow: hidden;
  height: calc(94vh - 200px);
}

.wcc-year-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2E7C9A;
  color: white;
  padding: 12px 20px;
  height: 36px;
}

.wcc-week-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 15px;
}

.wcc-nav-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.wcc-nav-button img {
  height: 45px;
  width: 45px;
}

.wcc-total-label {
  font-size: 16px;
  color: #4B5354;
}

.wcc-total-section {
  border-bottom: 1px solid #839699;
  display: flex;
  justify-content: space-between;
}

.wcc-total-amount {
  font-size: 28px;
  color: #2F3637;
}

.wcc-income-label {
  color: #01B6CD;
  font-weight: 500;
  font-size: 20px;
  align-self: center;
}

.wcc-cases-count {
  font-size: 20px;
  color: #2F3637;
  align-self: center;
}

.wcc-daily-list {
  overflow-y: auto;
  max-height: calc(100% - 180px);
  padding-right: 8px;
}

.wcc-daily-item {
  padding: 0 3px 0 20px;
  border-bottom: 1px solid #e5e7eb;
  background-color: #F9F9F9;
}

.wcc-daily-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
}

.wcc-day-info {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #666;
}

.wcc-arrow-icon2 {
  font-size: 12px;
  color: #999;
  transition: transform 0.2s;
  height: 25px;
  width: 25px;
  place-content: center;
  text-align: center;
}

.wcc-daily-header.expanded .wcc-arrow-icon2 {
  transform: rotate(90deg);
}

.wcc-treatments-table {
  background-color: #f8f9fa;
}

.wcc-treatments-table table {
  width: 100%;
  border-collapse: collapse;
}

.wcc-treatments-table th {
  background-color: #EFF2F3;
  color: #4B5354;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 20px;
  text-align: left;
  border-bottom: solid 1px #839699;
}

.wcc-treatments-table td {
  padding: 12px 20px;
  font-size: 16px;
}

.wcc-section {
  width: auto;
  background-color: #fff;
  padding: 0;
  border: none;
  border-radius: 15px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 0 20px -5px #a9a9a9;
  height: 100%;
}

/* Scrollbar Styles */
.wcc-daily-list::-webkit-scrollbar,
.wcc-settlement-table-container::-webkit-scrollbar {
  width: 8px;
}

.wcc-daily-list::-webkit-scrollbar-track,
.wcc-settlement-table-container::-webkit-scrollbar-track {
  background: #2E7C9A;
  border-radius: 4px;
}

.wcc-daily-list::-webkit-scrollbar-thumb,
.wcc-settlement-table-container::-webkit-scrollbar-thumb {
  background: #2E7C9A;
  border-radius: 4px;
}

.wcc-daily-list::-webkit-scrollbar-thumb:hover,
.wcc-settlement-table-container::-webkit-scrollbar-thumb:hover {
  background: #236b85;
}

.wcc_incomeBtn{
  border: none;
  color: #fff;
  background-color: #01b6cd;
  font-size: 16px;
  height: 30px;
  border-radius: 10px;
  width: 80px;
  cursor: pointer;
}

.wcc_incomeBtn:hover{
  background-color: #236b85;
}

.wcc-patient-info-Event_container{
  border: 1px solid #ddd;
  overflow-y: auto;
  height: 95%;
  flex-shrink: 0;
  width: 25%;
  min-width: 385px;
  background: #FFFFFF;
}