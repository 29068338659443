/* Login.css */

input::-ms-reveal,
input::-ms-clear {
    display: none;
}

.login-page-container {
    background-color: #F1F9FC;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.login-header {
    align-items: center;
    background-color: #fff;
    display: flex;
    padding: 10px;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.logo-image {
    height: 35px;
}

.btnClear, 
.toggle-password {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 25px;
    position: absolute;
    right: 0px; /* 비밀번호 토글 버튼과 겹치지 않도록 조정 */
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    width: 55px;
    height: 40px;
  }

  .password-toggle-icon {
    width: 150%;
}

.btnClear:focus,
.toggle-password:focus{
    outline: none;
}

.login-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 40px 20px;
}

.login-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: -8px 0px 6px -6px rgba(0, 0, 0, 0.1), 0px 8px 6px -6px rgba(0, 0, 0, 0.1), 8px 0px 6px -6px rgba(0, 0, 0, 0.1);
    width: 440px;
    max-width: 100%;
    overflow: hidden;
}

/* Tab Navigation */
.login-tabs {
    display: flex;
    width: 100%;
    background: #F1F9FC;
}

.tab-button-do {
    flex: 1;
    padding: 15px;
    border: none;
    background-color: #F0F0F0;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tab-button-re {
    flex: 1;
    padding: 15px;
    border: none;
    background-color: #F0F0F0;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tab-button-do.active-do {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #fff;
    color: #00B2C2;
}
.tab-button-re.active-re {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #00B2C2;
    color: #ffffff;
}

/* Welcome Section */
.login-welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 40px 20px;
}

.welcome-icon {
}

.welcome-icon img {
    height: 70px;
}

.welcome-heading {
    color: #00B2C2;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
}

.welcome-subtext {
    color: #5e5e5e;
    font-size: 16px;
}

/* Form Styling */
.login-form {
    padding: 0 40px 5px;
}

.input-field {
    position: relative;
    margin-bottom: 24px;
}


.input-field input {
    background-image: url(./icons/img_email.png);
    width: 87%;
    padding: 12px 12px 12px 36px;
    border: none;
    border-bottom: 2px solid #ccc;
    font-size: 16px;
    background-color: transparent;
    transition: border-color 0.3s;
    outline: none;
}

.email-icon {
    background-image: url(./icons/img_email.png);
    width: 45px;
    height: 45px;
    background-size: contain;
    display: inline-block;
}

.password-icon {
    background-image: url(./icons/img_password.png);
    width: 45px;
    height: 45px;
    background-size: contain;
    display: inline-block;
}


.input-field.focused input {
    border-color: #00B2C2;
    border-bottom-width: 2px;
}

.input-field.error input {
    border-color: red;
    border-bottom-width: 2px;
}

.input-icon {
    position: absolute;
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
}

.lg_img {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 40px;
    height: 40px;
}

.password-toggle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.password-toggle img {
    width: 20px;
    height: 20px;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 4px;
    float: right;
}

/* Form Options */
.form-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

/* Remember Me Checkbox */
.remember-option {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    color: #5e5e5e;
    user-select: none;
}

.remember-option input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.checkbox-custom {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 8px;
}

.remember-option input:checked ~ .checkbox-custom:after {
    content: "";
    position: absolute;
    display: block;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #00B2C2;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Forgot Password Link */
.forgot-password-link {
    background: none;
    border: none;
    color: #00B2C2;
    font-size: 14px;
    cursor: pointer;
}

/* Login Button */
.login-button {
    width: 100%;
    padding: 14px;
    background-color: #00B2C2;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.login-button:hover {
    background-color: #019aad;
}

.login-error {
    color: red;
    text-align: center;
    margin-top: 12px;
    font-size: 14px;
}

/* Sign Up Prompt */
.signup-prompt {
    padding: 20px 40px;
    color: #5e5e5e;
    font-size: 14px;
    text-align: center;
}

.signup-link {
    background: none;
    border: none;
    color: #00B2C2;
    font-weight: 500;
    cursor: pointer;
    padding: 0 4px;
}

/* Responsive Adjustments */
@media (max-width: 480px) {
    .login-card {
        border-radius: 0;
        box-shadow: none;
        width: 100%;
    }
    
    .login-welcome {
        padding: 20px;
    }
    
    .login-form {
        padding: 0 20px 20px;
    }
    
    .form-options {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }
}
/**/

