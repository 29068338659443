.PWC-app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
  }
  
  .PWC-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 1;
  }
  
  .PWC-content {
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: center;
  }
  
  .PWC-gene-img {
    height: 37px;
    width: 37px;
  }
  
  .PWC-notification-button {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .PWC-sidebar {
    background-color: #ffffff;
    overflow: hidden;
    max-width: 1200px;
    width: 60%;
  }
  
  .PWC-main-content {
    display: flex;
    flex: 1;
    background-color: #ffffff;
  }
  
  .PWC-day-info .arrow-icon{
    transform: rotate(90deg);
  }
  
  .PWC-user-calls-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    -ms-overflow-style: none;
    padding-bottom: 15px;
    height: calc(100vh - 180px);
    min-height: 500px; /* 최소 높이 설정 */
    max-height: 800px; /* 최대 높이 설정 */
  }
  
  .PWC-user-calls-list::-webkit-scrollbar {
    width: 10px;
    border-radius: 50px;
    border: none;
    background-color: #D3D8D9;
  }
  
  .PWC-user-calls-list::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 50px;
    border: none;
    background-color: #2E7C9A;
  }
  
  .PWC-user-call {
    background-color: #ffffff;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .PWC-chat-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    position: relative;
    height: 100%;
    width: 100%;
  }
  
  .PWC-patient-info-container {
    border: 1px solid #ddd;
    overflow-y: auto;
    height: 100%;
    flex-shrink: 0;
    width: 25%;
    min-width: 385px;
    background: #FFFFFF;
  }
  
  .PWC-patient-info-container::-webkit-scrollbar {
    display: none;
  }
  
  .PWC-search {
    display: flex;
    align-items: center;
    height: auto;
  }
  
  .PWC-gene-div {
    background-color: #fff;
    display: flex;
    gap: 22px;
    padding: 0 12px;
    width: 100%;
    justify-content: center;
  }
  
  .PWC-drprofile-img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
  
  .PWC-drprofile-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .PWC-tabs {
    display: flex;
    position: relative;
    font-family: 'Actor', sans-serif;
  }
  
  .PWC-tab {
    flex: 1;
    padding: 10px;
    text-align: center;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #A8A7A7;
    font-family: 'Actor', sans-serif;
  }
  
  .PWC-tab.active[data-tab='active'] {
    color: #03CA16;
    font-family: 'Actor', sans-serif;
    font-size: 20px;
    font-weight: bold;
    z-index: 2;
  }
  
  .other-tab-count {
    padding: 0px 5px;
    font-size: 20px;
    color: #fa5252;
    font-weight: bold;
  }
  
  .PWC-tab.active[data-tab='inactive'] {
    color: #FA5252;
    font-family: 'Actor', sans-serif;
    font-size: 20px;
    font-weight: bold;
    z-index: 2;
  }
  
  .PWC-tab-indicator {
    position: absolute;
    bottom: 0;
    height: 3px;
    z-index: 1;
  }

  .PWC-tab-indicator-background{
    position: absolute;
    top: 43px;
    width: 100%;
    height: 1px;
    z-index: 0;
    background-color: #B9C3C4;
  }
  
  .PWC-tab-indicator.active {
    background-color: #03CA16;
    width: 50%;
  }
  
  .PWC-tab-indicator.inactive {
    background-color: #FA5252;
  }
  
  .PWC-logo-image {
    width: auto;
    height: 40px;
    margin-right: 40px;
  }
  
  .PWC-search-container {
    position: relative;
    width: 100%;
    margin: 14px 0;
  }
  
  .PWC-search-icon {
    position: absolute;
    left: 92%;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    pointer-events: none;
    opacity: 0.5;
  }
  
  .PWC-search-input {
    width: 98%;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    background-color: #f5f5f5;
    color: #333;
    padding-top: 1.5%;
    padding-bottom: 1.5%;
    padding-left: 2%;
  }
  
  .PWC-search-input::placeholder {
    padding-left: 4%;
    color:#B9C3C4;
  }

  .PWC-ListBox{
    overflow: hidden;
    height: 650px;
    overflow-x: hidden;
  }

  .PWC-ListBox::-webkit-scrollbar{
    display:none;
  }

  .expandBtn {
    position: absolute;
    left: 77%;
    bottom: 80px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 3;
    transition: all 0.3sease;
  }

  .expandBtn img {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
  }
  
  .PWC-search-input:focus {
    border: none;
  }

  .PWC-ListBox.expanded + .expandBtn {
    top: calc(95vh - 40px);
  }
  
  .PWC-header-right {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .PWC-logout {
    margin-right: 15px;
    padding: 8px 15px;
    background-color: #ff5f5f;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .PWC-rate-container {
    align-items: center;
    display: flex;
    margin-right: 15px;
    font-weight: bold;
    font-size: 16px;
  }
  
  .PWC-rate-img {
    width: 27px;
    height: 27px;
    margin-right: 5px;
  }
  
  .PWC-income-button {
    margin-left: 10px;
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .PWC-income-button:hover {
    background-color: #45a049;
  }
  
  .PWC-main-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #F1F9FC;
  }
  
  .income-page {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
  }
  
  .PWC-call-list {
    background-color: #fff;
    padding: 15px;
    margin: 10px;
    border-radius: 15px;
    box-shadow: 0px 4px 20px 0px #2E7C9A33;
  }
  
  .PWC-call-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .PWC-call-icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .PWC-call-list-title {
    font-size: 24px;
    font-weight: bold;
    color: #2E7C9A;
  }
  
  .PWC-call-count {
    font-size: 20px;
    font-weight: 400;
    color: #2E7C9A;
  }
  
  /* Settlement Details Styles */
  .PWC-settlement-details {
    padding: 20px;
    width: 100%;
    overflow-y: auto;
  }
  
  .PWC-settlement-header-container {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 30px 20px 0px 20px;
  }
  
  .PWC-settlement-header-img {
    width: 40px;
    height: 40px;
  }
  
  .PWC-settlement-title {
    font-size: 20px;
    color: #2E7C9A;
    font-weight: bold;
  }
  
  .PWC-settlement-table-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin: 20px 0;
    background: white;
    border-radius: 8px;
    max-height: 600px;
    overflow-y: auto;
  }
  
  .PWC-income-table {
    min-width: 750px;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  
  .PWC-income-table th {
    background-color: #2E7C9A;
    padding: 16px;
    text-align: left;
    font-weight: 600;
    color: #fff;
    font-size: 16px;
    white-space: nowrap;
    position: sticky;
    top: 0;
  }
  
  .PWC-income-table td {
    padding: 12px;
    border-bottom: 1px solid #A8A7A7;
    font-size: 14px;
  }
  
  .PWC-income-table td:first-child {
    padding-left: 100px;
  }
  
  .PWC-income-status-completed {
    color: #01B6CD;
    font-size: 16px;
    font-weight: bold;
  }
  
  .PWC-income-status-pending {
    color: #839699;
    font-size: 16px;
  }
  
  .PWC-arrow-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .PWC-arrow-icon {
    height: 24px;
    width: 24px;
    transition: transform 0.2s ease;
  }
  
  /* Weekly View Styles */
  .PWC-weekly-view {
    background: white;
    overflow: hidden;
    height: calc(94vh - 200px);
  }
  
  .PWC-year-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2E7C9A;
    color: white;
    padding: 12px 20px;
    height: 36px;
  }
  
  .PWC-week-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 15px;
  }
  
  .PWC-nav-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
  }
  
  .PWC-nav-button img {
    height: 45px;
    width: 45px;
  }
  
  .PWC-total-label {
    font-size: 16px;
    color: #4B5354;
  }
  
  .PWC-total-section {
    border-bottom: 1px solid #839699;
    display: flex;
    justify-content: space-between;
  }
  
  .PWC-total-amount {
    font-size: 28px;
    color: #2F3637;
  }
  
  .PWC-income-label {
    color: #01B6CD;
    font-weight: 500;
    font-size: 20px;
    align-self: center;
  }
  
  .PWC-cases-count {
    font-size: 20px;
    color: #2F3637;
    align-self: center;
  }
  
  .PWC-daily-list {
    overflow-y: auto;
    max-height: calc(100% - 180px);
    padding-right: 8px;
  }
  
  .PWC-daily-item {
    padding: 0 3px 0 20px;
    border-bottom: 1px solid #e5e7eb;
    background-color: #F9F9F9;
  }
  
  .PWC-daily-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    cursor: pointer;
  }
  
  .PWC-day-info {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #666;
  }
  
  .PWC-arrow-icon2 {
    font-size: 12px;
    color: #999;
    transition: transform 0.2s;
    height: 25px;
    width: 25px;
    place-content: center;
    text-align: center;
  }
  
  .PWC-daily-header.expanded .PWC-arrow-icon2 {
    transform: rotate(90deg);
  }
  
  .PWC-treatments-table {
    background-color: #f8f9fa;
  }
  
  .PWC-treatments-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .PWC-treatments-table th {
    background-color: #EFF2F3;
    color: #4B5354;
    font-size: 16px;
    font-weight: bold;
    padding: 12px 20px;
    text-align: left;
    border-bottom: solid 1px #839699;
  }
  
  .PWC-treatments-table td {
    padding: 12px 20px;
    font-size: 16px;
  }
  
  .PWC-section {
    width: auto;
    background-color: #fff;
    padding: 0;
    border: none;
    border-radius: 15px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 0 20px -5px #a9a9a9;
    height: 100%;
  }
  
  /* Scrollbar Styles */
  .PWC-daily-list::-webkit-scrollbar,
  .PWC-settlement-table-container::-webkit-scrollbar {
    width: 8px;
  }
  
  .PWC-daily-list::-webkit-scrollbar-track,
  .PWC-settlement-table-container::-webkit-scrollbar-track {
    background: #2E7C9A;
    border-radius: 4px;
  }
  
  .PWC-daily-list::-webkit-scrollbar-thumb,
  .PWC-settlement-table-container::-webkit-scrollbar-thumb {
    background: #2E7C9A;
    border-radius: 4px;
  }
  
  .PWC-daily-list::-webkit-scrollbar-thumb:hover,
  .PWC-settlement-table-container::-webkit-scrollbar-thumb:hover {
    background: #236b85;
  }
  
  .PWC_incomeBtn{
    border: none;
    color: #fff;
    background-color: #01b6cd;
    font-size: 16px;
    height: 30px;
    border-radius: 10px;
    width: 80px;
    cursor: pointer;
  }
  
  .PWC_incomeBtn:hover{
    background-color: #236b85;
  }
  
  .PWC-patient-info-Event_container{
    border: 1px solid #ddd;
    overflow-y: auto;
    height: 95%;
    flex-shrink: 0;
    width: 25%;
    min-width: 385px;
    background: #FFFFFF;
  }

  /*----------------------------*/
  .PWC-indexName{
    display: flex;
    gap: 85px;
    justify-content: space-between;
    padding: 0 40px 0 40px;
    border-bottom: solid 1px #B9C3C4;
    align-items: center;
  }

  .PWC-publicIN{
    font-size: 15px;
    color: #A8A7A7;
    margin: 10px;
  }
  
  .IN1{

  }

  .IN2{
    
  }

  .IN3{
    background-color: #ffffff00;
    border: none;
    cursor: pointer;
    display: flex
;
    align-items: center;
    gap: 10px;
  }

  .PWC-publicIN_IN3_btn1{
    display: contents;
    cursor: pointer;
  }

  .sortingPopup{
    position: absolute;
    top: 230px;
    right: 460px;
    width: 172px;
    z-index: 3;
    height: 98px;
    background-color: #ffffff;
    border-radius: 5px;
    border: solid 1px #E6E6E6;
    box-shadow: 0px 0px 5px #d6d6d6;
    padding: 10px;
    display: inline-grid
;
  }

  .sort_type{
    font-size: 14px;
    font-weight: bold;
    color: #767676;
    align-content: center;
  }

  .sort_type:hover{
    color: #2E7C9A;
    cursor: pointer;
  }

  .PI-Script{
  }

  .PWC-IN-container{
    display: flex;
    align-items: center;
  }

  .PI-Script {
    position: relative;
    cursor: pointer;
    color: #2E7C9A;
    font-weight: bold;
    display: flex
;
  }
  
  .tooltip {
    position: absolute;
    top: 30px;
    left: 0;
    background-color: #4B4B4B;
    color: white;
    border-radius: 8px;
    padding: 15px;
    width: 400px;
    z-index: 100;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
  }
  
  .PI-Script:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
  
  .tooltip-header {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 8px;
  }
  
  .tooltip-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .tooltip-indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .tooltip-green {
    background-color: #03CA16;
  }
  
  .tooltip-red {
    background-color: #FA5252;
  }

  .PageIndex {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-top: 1px solid #B9C3C4;
  }
  
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  
  /* Add hover effects for the buttons */
  .pagination-container button:hover {
    background-color: #f5f5f5;
  }
  
  .pagination-container button.active:hover {
    background-color: #01B6CD;
  }
  
  /* Animation for page change */
  @keyframes fadeIn {
    from { opacity: 0.5; }
    to { opacity: 1; }
  }
  
  .pagination-container button {
    animation: fadeIn 0.3s ease-in-out;
  }

  .PWC-ListBox.expanded {
    height: calc(100vh - 200px);
    max-height: none;
    overflow-y: auto;
  }
  
  .PWC-ListBox.expanded::-webkit-scrollbar {
    width: 10px;
    border-radius: 50px;
    border: none;
    background-color: #D3D8D9;
  }
  
  .PWC-ListBox.expanded::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 50px;
    border: none;
    background-color: #2E7C9A;
  }

  /*--------------*/
  /* Add or update these styles in your PreWebChatPage.css file */

/* Refined ListBox styling for better expansion behavior */
.PWC-ListBox {
  height: calc(100vh - 250px);
  max-height: 650px; /* Default max height */
  overflow-y: auto;
  transition: all 0.3s ease;
  position: relative;
}

/* When expanded, take more vertical space */
.PWC-ListBox.expanded {
  max-height: calc(85vh - 150px);
  height: calc(100vh - 150px);
  overflow-y: auto;
  padding-bottom: 40px; /* Add padding to ensure content is visible above the expand button */
}

/* Show scrollbar when expanded */
.PWC-ListBox.expanded::-webkit-scrollbar {
  display: block;
  width: 10px;
  border-radius: 50px;
  background-color: #D3D8D9;
}

.PWC-ListBox.expanded::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 50px;
  background-color: #2E7C9A;
}

/* Position the expand button properly */
.expandBtn {
  position: absolute;
  bottom: 80px;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Adjust button position when expanded */
.PWC-ListBox.expanded + .expandBtn {
  bottom: 20px;
}

/* Hide pagination when expanded */
.PWC-ListBox.expanded + .expandBtn + .PageIndex {
  display: none;
}

/* Expand button hover effect */
.expandBtn:hover {
  background-color: #f5f5f5;
}

.expandBtn img {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}