@font-face {
    font-family: 'Signatie';
    src: url('./fonts/Signatie.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

.PSCR-Prescription{
    position: absolute;
    width: 637px;
    height: 765px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding:30px 30px 30px 30px;
    padding: 10px;
    top: 100px;
}
.PSCR-Title{
    color: #2F3637;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    padding: 20px 0;
}
.PSCR-SubTitle{
    color: #2F3637;
    font-size: 20px;
    font-weight: bold;
}
.PSCR-ContentsText{
    color: #2F3637;
    font-size: 16px;
    font-weight: 300;
    padding-top: 10px;
}
.PSCR-ContentsText.Gray{
    color: #A8A7A7;
}
.PSCR-ContentsText.White{
    color: #FFFFFF;
}
.PSCR-ContentTextArea{
    width: 555px;
    height: 38px;
    resize: none;
    border: solid 1px #9CABAD;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
    font-size: 14px;
    color: #000000;
}

.PSCR-ScrollArea{
    height: 76%;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 20px 5px 30px 20px;
}

.PSCR-ScrollArea::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
    border: none;
    background-color: #D9D9D9;
}
  
.PSCR-ScrollArea::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 50px;
    border: none;
    background-color: #767676;
}

.PSCR-SubContainer{
    margin: 10px 0 10px
}
.PSCR-BtnContainer{
    display: flex;
    padding: 0px 10px 30px 10px;
    gap: 20px;
    justify-content: center;
}

.PSCR-SignatureContainer{
    display: flex;
    flex-direction: row;
}
.PSCR-SignatureLeft{}
.PSCR-SignatureRight{
    font-family: 'Signatie', cursive;
    width: 70%;
    align-self: center;
    text-align: center;
}

.PSCR-Btn{
    width: 131px;
    height: 41px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    align-content: center;
}
.PSCR-Btn.cancle{
    background-color: #9CABAD;
    cursor: pointer;
}
.PSCR-Btn.Next{
    background-color: #01B6CD;
    cursor: pointer;
}

.Util-LeftPadding-15px{
    padding-left: 15px;
}
.Util-TopPadding-10px{
    padding-top: 10px;
}
.Util-TopPadding-20px{
    padding-top: 20px;
}